.jof-features {
    &__list {

        @include respond(sm) {
           width: 90%;
           margin-left: auto;
           margin-right: auto;
          }
    }

  &__item {
    display: flex;
    gap: 2rem;
    align-items: flex-start;

    @include respond(sm) {
      flex-direction: column;
      gap: 0;
    }
  }

  &__item-icon {
    color: $color-primary;

    span {
      font-size: 6rem;
    }
  }

  &__item-body {
    margin-top: 2rem;

    @include respond(sm) {
        margin-top: 0;
    }

    h4 {
      font-size: 2rem;
      font-weight: 600;
    }
  }
}

.crypto-bg {
    background-image: linear-gradient(to right, rgba(0,0,0, 0.5), rgba(0,0,0, 0.5), rgba(0,0,0, 0.5)), url(../../assets/images/crypto_bg.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__btn {
        width: max-content;
        background-color: $color-primary;
        font-size: 3rem;
        border-radius: 1rem;
        padding: .6rem 1.5rem;
        border: none;
        color: $color-white;
        font-weight: 600;
        transition: all .3s ease-out;
    
        a {
            text-decoration: none;
            color: inherit;
        }
    
        &:hover {
            background-color: $color-primary-dark;
            transform: scale(1.1);
        }
    }
}

.jof-testimonials {
  font-size: 3rem;
}

.jof-contact-us {
  margin-top: -11rem;
}