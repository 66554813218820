.jof-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
  box-shadow: 4px 4px 1px rgba(0, 0, 0, 0.1);
  padding: 0 3rem;
  background-color: $color-white;

  z-index: 3000;

  @include respond(sm) {
    padding: 0 1rem;
  }

  a {
    color: $color-primary-dark;

    // &:hover {
    //   font-weight: 600;
    // }
  }

  &__logo {  
    font-weight: 600;
    font-family: Verdana, Geneva, Tahoma, sans-serif;

    a {
      font-size: 2.1rem;

      @include respond(sm) {
        font-size: 2.5rem;
      }
      @include respond(xxs) {
        font-size: 2rem;
      }
    }
  }

  &__items {
    display: flex;
    gap: 2.2rem;
    height: 100%;
    align-items: center;

    @include respond(md) {
      display: none;
    }
  }

  &__item {
    // margin-left: 2rem;
    display: inline-block;
    box-sizing: border-box;

    &:hover {
        border-bottom: 4px solid $color-primary;
    }
  }

  &__menu {
    display: none;
    font-size: 3rem;
    cursor: pointer;

    @include respond(md) {
      display: block;
    }
  }
}
