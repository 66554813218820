.web3-DApp {
  z-index: 5000 !important;

//   &__modal-content {
//     background-image: url(../../../public/img/web3-dapp-bg.jpg);
//     background-repeat: no-repeat;
//     background-size: cover;
//   }

  &__wallet {
    cursor: pointer;

    &:hover {
      background-color: $color-black !important;
      color: $color-white;
    }
  }

  &__input {
    height: 10rem !important;
  }

  &__feedback {
    font-size: 2rem;
  }
}

.bg-web3-primary {
  background-color: $color-primary-dark;
}
