*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  // This defines what 1rem is
  font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%

  @include respond(lg) {
    // width > 992?
    font-size: 50%; //1 rem = 8px, 8/16 = 50%
  }

  @include respond(xl) {
    // width > 1200?
    font-size: 56.25%; //1 rem = 9px, 9/16 = 50%
  }

  @include respond(xxl) {
    font-size: 75%; //1rem = 12, 12/16
  }
}

body {
  box-sizing: border-box;
  scroll-behavior: smooth;
  padding-top: 6rem;

  @include respond(md) {
    padding-top: 10rem;
  }
}

a {
  text-decoration: none;
}

// The ::selection CSS pseudo-element applies styles to the part of a
// document that has been highlighted by the user
// (such as clicking and dragging the mouse across text).
::selection {
  background-color: $color-primary;
  color: $color-white;
}

.jof-container {
  padding: 0 6rem;
  width: 100%;

  @include respond(sm) {
    padding: 0 1.5rem;
  }
}

.display-desktop {
  @include respond(sm) {
    display: none;
  }
}

.display-mobile {
  display: none;
  @include respond(sm) {
    display: block;
  }
}