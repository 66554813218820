// text
.u-center-text { text-align: center !important; }


// margins
.u-margin-top-small { margin-top: 1.5rem !important; }
.u-margin-top-medium { margin-top: 4rem !important; }
.u-margin-top-large { margin-top: 8rem !important; }

.u-margin-bottom-small { margin-bottom: 1.5rem !important; }
.u-margin-bottom-medium { margin-bottom: 4rem !important; }
.u-margin-bottom-large { margin-bottom: 8rem !important; }

.u-margin-left-small {
    padding-left: 1.5rem;
}
.u-margin-left-medium {
    padding-left: 4rem;
}
.u-margin-left-large {
    padding-left: 8rem;
}

.color-primary-dark {
    background-color: $color-primary-dark;
}