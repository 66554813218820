.jof-wallet {
  background-color: $color-off-white;

  &__crypto-col {
    @include respond(md) {
      padding-left: 20%;
      padding-right: 20%;
    }
    @include respond(sm) {
      padding-left: 15%;
      padding-right: 15%;
    }
  }
  &__crypto {
    padding: 1rem 0;
    // background-color: rgba($color-primary, .7);
    // width: 180px;
    // height: 180px;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid $color-white;
    transition: all 0.4s ease-in-out;

    &:hover {
      border: 2px solid $color-primary;
    }
  }
}

.jof-wallet-connect {
  color: $color-black;
}

.web3-DApp {
  &__wallet {
    cursor: pointer;

    &:hover {
      background-color: $color-black !important;
      color: $color-white;
    }
  }

  &__form-container {
    form {
      width: 80%;
    }
  }

  &__input {
    height: 10rem !important;
    width: 100%;
  }

  &__feedback {
    font-size: 2rem;
  }
}
