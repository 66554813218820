.jof-sidebar {
  display: none;
  visibility: hidden;
  height: 100vh;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  color: $color-white;
  background-color: $color-gray-dark-2;
  font-size: 2rem;
  z-index: 5000;

  transition: all 1s ease-in-out;

  @include respond(md) {
    display: block;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 2rem 2rem;
  }

  &__title {
    font-size: 2.4rem;
    font-weight: 600;
    color: $color-primary;
  }

  &__btn-close {
    color: white;
    font-size: 2rem;
  }

  &__body {
    margin-top: 2rem;
    padding: 0 3rem;
  }

  &__list {
    width: 100%;
  }

  &__item {
    margin-bottom: 2rem;
    border-bottom: 1px solid $color-primary-dark;

    a {
      color: $color-white;
      font-size: 1.8rem;
      font-weight: 600;
    }

    a:hover {
      color: $color-primary-dark;
    }
  }

  &__out {
    visibility: visible;
    animation: sidebar-out 1s ease-in-out 1;
  }

  &__in {
    visibility: hidden;
    animation: sidebar-in 1s ease-in-out 1;
  }
}

@keyframes sidebar-out {
  0% {
    transform: translateX(-60%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes sidebar-in {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-60%);
  }
}