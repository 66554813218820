.jof-form {
    width: 100%;

    &__control {
        width: 100%;
        margin-bottom: 1.5rem;

        label {
            display: block;
        }

        input {
            width: 100%;
            border-radius: 5px;
            outline: none;
            border: 2px solid $color-white;
            box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.3);
            padding: 3px 8px;

            &:focus {
                border: 2px solid $color-primary;
            }
        }

        textarea {
            width: 100%;
            height: 120px;
            border-radius: 5px;
            outline: none;
            border: 2px solid $color-white;
            box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.3);
            padding: 3px 8px;

            &:focus {
                border: 2px solid $color-primary;
            }
        }

       
    }


    &__btn {
        width: 100px;
        margin: 0 auto;
        
        button {
            width: 100%;
            height: 35px;
            background-color: $color-primary-dark;
            color: white;
            font-weight: 600;
            border: none;
            border-radius: 5px;
           
            &:hover {
                background-color: rgba($color-primary-dark, 0.8);
            }
        }
    }
}