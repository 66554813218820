// COLORS

$color-primary: #1266f1; //(18, 102, 241)
$color-primary-dark: #0ba8e6; //(11,168,230)

$color-secondary: #090709; //(128,0,128)
$color-secondary-dark: #2e1114; //(46,17,20)

$color-gray: #808080; //(128,128,128)
$color-gray-dark: #596a74;
$color-gray-dark-2: #333;

$color-tertiary: #fcd535; //(252, 213, 53)
$color-sky-tertiary: #b08e04;

$color-white: #fff; //(255,255,255)
$color-fade-white: #bfbfc1;
$color-off-white: #faf9f6;
$color-black: #000; //(0,0,0)

// FONT
$default-font-size: 1.6rem;
$small-font-size: 1.8rem;
$medium-font-size: 2rem;

:root {
  --color-secondary: #800080;
  --color-secondary-dark: #2e1114; //(46,17,20)

  --color-gray: #808080; //(128,128,128)
  --color-gray-dark: #596a74;
  --color-gray-dark-2: #333;

  --shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
  --shadow-light: 0 2rem 5rem rgba(0, 0, 0, 0.06);

  --line: 1px solid var(--color-gray);
}
