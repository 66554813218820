.jof-footer {
    padding-top: 3rem;
    background-color: $color-off-white;

    &__coin-logos {
        display: flex;
        gap: 3rem;
        justify-content: center;
        margin-bottom: 3rem;
        flex-wrap: wrap;

        span {
            font-size: 5rem;
            color: $color-primary-dark;
        }

        span:first-child {
            color: $color-black;
        }
        span:nth-child(2) {
            color: $color-black;
        }
        span:nth-child(4) {
            color: violet;
        }
        span:last-child {
            color: $color-primary;
        }

        @include respond(sm) {
            span {
                font-size: 3.5rem;
            }
        }
    }

    &__main {
        margin-top: 3rem;
    }

    &__logo {
        font-weight: 600;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
    
        a {
          font-size: 3rem;
          color: $color-primary-dark;
    
        }
    }

    &__socials {
        display: flex;
        gap: 2rem;

        a {
            color: $color-black;
            font-size: 33px;
        }

        @include respond(sm) {
            a {
                color: $color-primary;
                font-size: 25px;
            }
        }
    }

    &__links-form {
        display: flex;
        justify-content: space-between;


        @include respond(sm) {
            flex-direction: column;
            gap: 3rem;
        }
    }

    &__links {
        display: flex;
        gap: 3rem;
        margin-top: 3rem;

        a {
            color: $color-primary-dark;
        }
    }

    &__form-container {
        box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.3);
        color: $color-primary-dark;
        padding: 15px 23px;

        h4 {
            font-weight: 600;
        }

        form {
            display: flex;

            @include respond(sm) {
                flex-direction: column;
                gap: .5rem;
                align-items: center;
            }
        }

        input {
            outline: none;

            border: 2px solid $color-white;
            box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.3);
            padding: 3px 8px;

            &:focus {
                border: 2px solid $color-primary;
            }
        }

        button {
            outline: none;
            background-color: $color-primary-dark;
            color: white;

            border: 2px solid $color-white;
            box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.3);
            padding: 3px 8px;

            &:hover {
                background-color: rgba($color-primary-dark, 0.8);
            }
        }
    }
}