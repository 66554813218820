body {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    /*font-size: 16px;*/
    line-height: 1.7;
    color: $color-black;
    font-size: 1.4rem;

    @include respond(sm) {
        font-size: $default-font-size;
    }
}

.primary-header {
    font-size: 5rem;
    font-weight: 700;

    @include respond(md) {
        font-size: 4rem;
    }
}

.secondary-header {
    font-size: 3rem;
    font-weight: 700;

    // @include respond(md) {
    //     font-size: 2rem;
    // }
}

.special-header {
    font-size: 5rem;
    font-weight: 700;
    color: $color-primary-dark;

    @include respond(sm) {
        font-size: 3.5rem;
    }
}

.tertiary-header-dark {
    color: $color-primary-dark;
}

.col-primary-dark {
    color: $color-primary-dark;
}

.primary-paragraph {
    font-size: 1.8rem;
}