.carousel-inner {
  margin-bottom: 40px;
}

.carousel-item {
  min-height: 350px;
  text-align: center;
}

.carousel-text {
  font-size: 2rem !important;
  margin-bottom: 2rem !important;
}

.carousel-footer {
  font-size: 2rem !important;
  margin-bottom: 2rem !important;
}

.carousel-caption {
  bottom: 0;
}

.carousel-control-prev,
.carousel-control-next {
  width: 7%;
  color: black !important;
  z-index: 50 !important;

  a {
    color: red !important;
    z-index: 50 !important;
  }
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 100%;
}

@media (max-width: 767px) {
  .carousel-control-prev,
  .carousel-control-next {
    width: 15%;
  }
}


