.jof-btn-primary {
  background-color: $color-primary;
  font-size: $default-font-size;
  border-radius: 1rem;
  padding: 0.6rem 1.5rem;
  border: none;
  color: $color-white;
  font-weight: 600;
  transition: all 0.3s ease-out;

  a {
    text-decoration: none;
    color: inherit;
  }

  &:hover {
    background-color: $color-primary-dark;
    transform: scale(1.1);
  }
}

.jof-btn-rounded {
  border-radius: 2rem;
  padding: 1rem 1.5rem;
  font-weight: 700;

  a {
    text-decoration: none;
    color: inherit;
  }
}

.jof-btn-scale {
  &:hover {
    transform: scale(0.8);
  }

  a {
    text-decoration: none;
    color: inherit;
    font-weight: 700;
  }
}

.jof-btn-style {
  background-color: $color-primary-dark;
  font-size: $default-font-size;
  border-radius: 0.5rem;
  padding: 0.6rem 1.5rem;
  border: none;
  color: $color-white;
  font-weight: 600;
  transition: all 0.3s ease-out;

  &:hover {
    background-color: rgba($color-primary-dark, 0.8);
  }
}
