.jof-nft {

  // &__list {
  //     padding-bottom: 25rem;
  // }

  &__list-item {
    color: $color-white;
    background-color: $color-primary-dark !important;
    border-color: $color-white;
    cursor: pointer;
  }

  &__logo {
    width: 30px;
    border-radius: 50%;
  }
}
