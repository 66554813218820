.jof-card-1 {
  cursor: pointer;

  min-height: 300px !important;
  margin: 0 auto;

  @include respond(md) {
    min-height: max-content !important;
  }

  &:hover {
    border: 1px solid $color-primary-dark !important;
  }

  &__header {
    background-color: $color-primary-dark !important;
  }

  &__body {
    color: $color-black !important;
  }

  &__title {
    font-weight: 600;
    color: $color-primary-dark !important;
  }

  &__text {
    font-size: 1.4rem;
  }
}
